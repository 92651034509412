import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encryption-of-resources"
    }}>{`Encryption of resources`}</h1>
    <p>{`This section describes how AWS resources encryption mechanism works and how to configure KMS keys in order for this to work properly.`}</p>
    <p>{`Encrypting resources during replication to the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account depends on KMS keys created in the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account. These keys should be shared to the `}<inlineCode parentName="p">{`PROTECTED`}</inlineCode>{` account using KMS keys policies. Resources' encrypted snapshots can't be replicated to the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account without re-encrypting using KMS keys defined in the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account. The alias of keys used for re-encryption is specified in the backup setup definition in `}<inlineCode parentName="p">{`Replicas encryption KMS key ARN or alias`}</inlineCode>{` field. Plase note that KMS keys are defined separately for each region. So, you must create KMS keys in each region to which you want to replicate your resources' snapshots and all of these keys must have the same alias.`}</p>
    <p>{`For example, let's say you have a `}<inlineCode parentName="p">{`Replicas encryption KMS key ARN or alias`}</inlineCode>{` field set to "very_secret_key" value. And you have 2 encrypted EBS volumes. You want to replicate one of them to the eu-west-1 region and the second one to the eu-central-1. Their snapshots in the `}<inlineCode parentName="p">{`PROTECTED`}</inlineCode>{` account will be encrypted using the same KMS key which was used for encrypting original volumes, but their replicas will be re-encrypted using keys defined in the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account. In this example, those keys must be created in the eu-west-1 and eu-central-1 regions and both of them must have "very_secret_key" alias.`}</p>
    <h3 {...{
      "id": "in-order-for-replication-with-encryption-to-work-you-must-do-the-following"
    }}>{`In order for replication with encryption to work you must do the following:`}</h3>
    <ul>
      <li parentName="ul">{`In the `}<inlineCode parentName="li">{`VAULT`}</inlineCode>{` account, for each region to which you want to replicate encrypted resources' snapshots, create custom KMS key to encrypt backups during replication process.`}</li>
      <li parentName="ul">{`Allow KMS keys to be used by IAM roles from both the `}<inlineCode parentName="li">{`VAULT`}</inlineCode>{` account (BackupIAMRole) and from the `}<inlineCode parentName="li">{`PROTECTED`}</inlineCode>{` account (SourceIAMRole).`}</li>
    </ul>
    <p>{`In general AutoBackup supports KMS keys in multiple ways:`}</p>
    <ul>
      <li parentName="ul">{`If protected resources are unencrypted you can encrypt them during replication process using `}<inlineCode parentName="li">{`VAULT`}</inlineCode>{` KMS key`}</li>
      <li parentName="ul">{`If protected resources are encrypted using `}<inlineCode parentName="li">{`PROTECTED`}</inlineCode>{` key you can re-encrypt them using `}<inlineCode parentName="li">{`VAULT`}</inlineCode>{` key during the replication process`}</li>
      <li parentName="ul">{`If protected resources are encrypted using `}<inlineCode parentName="li">{`VAULT`}</inlineCode>{` key you can just replicate them using a key from `}<inlineCode parentName="li">{`VAULT`}</inlineCode>{` account`}</li>
    </ul>
    <h4 {...{
      "id": "allowing-kms-keys-to-be-used-by-iam-roles"
    }}>{`Allowing KMS keys to be used by IAM roles`}</h4>
    <p>{`To replicate snapshots encrypted using the KMS key, the role created in your `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account must have permissions to use the key. It requires a modfication of the key policy. In order to allow a KMS key to be used by IAM role defined in the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account, go to the detailed view of the KMS key. In the `}<inlineCode parentName="p">{`Key Users`}</inlineCode>{` section find your role and add it to the list. To allow the key to be also used by a role defined in the `}<inlineCode parentName="p">{`PROTECTED`}</inlineCode>{` account, use `}<inlineCode parentName="p">{`Add External Account`}</inlineCode>{` option in the `}<inlineCode parentName="p">{`Key Users`}</inlineCode>{` section and provide the id of the `}<inlineCode parentName="p">{`PROTECTED`}</inlineCode>{` account.`}</p>
    <p>{`You can also allow KMS key to be used by the IAM roles by modifying the key policy directly. Example KMS key policy is shown below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Version": "2012-10-17",
  "Id": "example-key-policy",
  "Statement": [
    {
      "Sid": "Enable IAM User Permissions",
      "Effect": "Allow",
      "Principal": {
        "AWS": "arn:aws:iam::VAULT_ACCOUNT_ID:root"
      },
      "Action": "kms:*",
      "Resource": "*"
    },
    {
      "Sid": "Allow access for Key Administrators",
      "Effect": "Allow",
      "Principal": {
        "AWS": "arn:aws:iam::VAULT_ACCOUNT_ID:user/key_administrator@example.com"
      },
      "Action": [
        "kms:Create*",
        "kms:Describe*",
        "kms:Enable*",
        "kms:List*",
        "kms:Put*",
        "kms:Update*",
        "kms:Revoke*",
        "kms:Disable*",
        "kms:Get*",
        "kms:Delete*",
        "kms:TagResource",
        "kms:UntagResource",
        "kms:ScheduleKeyDeletion",
        "kms:CancelKeyDeletion"
      ],
      "Resource": "*"
    },
    {
      "Sid": "Allow use of the key",
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "arn:aws:iam::VAULT_ACCOUNT_ID:role/VAULT_ROLE_NAME",
          "arn:aws:iam::PROTECTED_ACCOUNT_ID:root"
        ]
      },
      "Action": [
        "kms:Encrypt",
        "kms:Decrypt",
        "kms:ReEncrypt*",
        "kms:GenerateDataKey*",
        "kms:DescribeKey"
      ],
      "Resource": "*"
    },
    {
      "Sid": "Allow attachment of persistent resources",
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "arn:aws:iam::VAULT_ACCOUNT_ID:role/VAULT_ROLE_NAME",
          "arn:aws:iam::PROTECTED_ACCOUNT_ID:root"
        ]
      },
      "Action": [
        "kms:CreateGrant",
        "kms:ListGrants",
        "kms:RevokeGrant"
      ],
      "Resource": "*",
      "Condition": {
        "Bool": {
          "kms:GrantIsForAWSResource": "true"
        }
      }
    }
  ]
}
`}</code></pre>
    <h4 {...{
      "id": "using-different-keys-for-encrypting-resources-in-protected-account-and-vault-account"
    }}>{`Using different keys for encrypting resources in `}<inlineCode parentName="h4">{`PROTECTED`}</inlineCode>{` account and `}<inlineCode parentName="h4">{`VAULT`}</inlineCode>{` account`}</h4>
    <p>{`When resources' snapshots replicas in the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account are meant to be encrypted using different keys than for resources in the `}<inlineCode parentName="p">{`PROTECTED`}</inlineCode>{` account, then the IAM role you've defined for the `}<inlineCode parentName="p">{`VAULT`}</inlineCode>{` account must be allowed to use the encryption key in the `}<inlineCode parentName="p">{`PROTECTED`}</inlineCode>{` account. You can grant an access to that key by modifying the encryption key policy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      